import React from "react";

const Iframe = ({ iframe, cssClass }) => {
  const defaultClassName = cssClass
    ? ["module module__iframe", cssClass].join(" ")
    : `module module__iframe`;

  let videoId = "";
  let regExp =
    /((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?/;
  let match = iframe.match(regExp);
  if (match && match[6].length === 11) {
    videoId = match[6];
  }

  const HTML = {
    __html: iframe,
  };

  return (
    <div className={defaultClassName}>
      {videoId !== "" ? (
        <div className="module__box iframe-scaler">
          <lite-youtube videoid={videoId}></lite-youtube>
        </div>
      ) : (
        <div
          className="module__box iframe-scaler"
          dangerouslySetInnerHTML={HTML}
        />
      )}
    </div>
  );
};

export default Iframe;
